import React, { ReactNode } from 'react';
import { Box, Button, Divider, Flex, Heading, Text, VStack, Wrap, WrapItem, HStack } from '@chakra-ui/react';
import useCopy from '../../hooks/useCopy';
import DocHeroPattern from '../../icons/DocHeroPattern';
import Link from '../../components/Link';
import { SectionCopy } from '../../copy/documentation/documentation';
import ContentContainer from '../../components/ContentContainer';

interface ProductTemplateProps {
  _copy: any;
}
interface FeatureCardProps {
  title: string;
  description: string;
  links?: [
    {
      label: string;
      to: string;
    }
  ];
}
interface SectionProps {
  children: ReactNode;
  title: string;
  horizontalStack?: boolean;
}

const FeatureCard = ({ title, description, links }: FeatureCardProps) => {
  return (
    <VStack
      key={title}
      h={{ lg: '245px' }}
      spacing="20px"
      alignItems="left"
      p="24px"
      mb={{ base: '20px', lg: '40px' }}
      border="1px solid #CED4DA"
    >
      <Text color="apryseNavy" fontWeight="700" fontSize="16px" lineHeight="24px" textTransform="uppercase">
        {title}
      </Text>
      <Divider w="24px" borderColor="apryseLightBlue" borderBottomWidth="3px" />
      <Text minH={{ lg: '95px' }} color="apryseNavy" fontWeight="400" fontSize="16px" lineHeight="24px">
        {description}
      </Text>
      {links && (
        <Flex>
          {links.map((link, index) => (
            <Flex key={link.label}>
              <Link className="FeatureLink" to={link.to} fontWeight="500" fontSize="16px" lineHeight="16px">
                {link.label}
              </Link>
              {index !== links.length - 1 && (
                <Divider orientation="vertical" h="20px" mx="16px" borderColor="apryseGray" />
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </VStack>
  );
};

const Feature = ({ title, description, links }: FeatureCardProps) => {
  return (
    <Box>
      <Text mb="8px" color="apryseNavy" fontWeight="700" fontSize="16px" lineHeight="24px" textTransform="uppercase">
        {title}
      </Text>
      <Text mb="20px" color="apryseNavy" fontWeight="400" fontSize="16px" lineHeight="24px">
        {description}
      </Text>
      {links && (
        <Box>
          {links.map((link) => (
            <Flex key={link.label} mb="8px">
              <Link className="FeatureLink" to={link.to} fontWeight="500" fontSize="16px" lineHeight="16px">
                {link.label}
              </Link>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};

const SectionContent = ({ section }: { section: SectionCopy }) => {
  if (!section.features) return null;
  if (section.horizontalStack) {
    return (
      <Flex w='full' flexDir={{ base: 'column', lg: 'row' }} justifyContent='flex-start' flexWrap='wrap'>
        {section.features.map((feature, idx) => (
          <Box
            key={idx}
            mr={{ base: '40px', lg: '60px' }}
            maxW={feature.fullWidth ? 'full' : '333px' }
            mt={idx === 0 ? 0 : { base: '40px', lg: 0 }}
          >
            <Feature title={feature.title} description={feature.description} links={feature.links} />
          </Box>
        ))}
      </Flex>
    );
  } else {
    return (
      <Flex flexWrap='wrap'>
        {section.features.map((feature) => {
          return (
            <Box
              key={feature.title}
              w={feature.fullWidth || feature.isCard ? { lg: 'full' } : { base: '100%', lg: '265px' }}
              mb={{ base: '20px', lg: '0px' }}
            >
              {feature.isCard ? (
                <FeatureCard
                  title={feature.title}
                  description={feature.description}
                  links={feature.links}
                ></FeatureCard>
              ) : (
                <Feature title={feature.title} description={feature.description} links={feature.links}></Feature>
              )}
            </Box>
          );
        })}
      </Flex>
    );
  }
};

const Section = ({ children, title, horizontalStack }: SectionProps) => {
  return (
    <WrapItem width={horizontalStack ? 'full' : { base: 'full', lg: '530px' }} flexDir='column'>
      <Heading as='h4' mb='20px' color='aprysePrimaryFontGray' fontWeight='300' fontSize='24px' lineHeight='32px'>
        {title}
      </Heading>
      {children}
    </WrapItem>
  );
};

export default function ProductTemplate({ _copy }: ProductTemplateProps) {
  const copy = useCopy(_copy);
  return (
    <>
      <Box height={{base: '250px', sm: '216px'}} bgColor="apryseLightGray">
        <ContentContainer noPadding>
        <Box display='flex' flexDir='column' justifyContent='center' h='100%'>
          <VStack
            spacing="8px"
            alignItems="left"
            mr={{ base: '24px', sm: '0px' }}
          >
            <Heading
              as="h1"
              color="apryseIndigo"
              fontWeight="300"
              fontSize={{ base: '28px', sm: '40px' }}
              line-height="48px"
              textAlign="left"
            >
              {copy.title}
            </Heading>
            <Text
              color="apryseNavy"
              fontWeight={{ base: '300', sm: '400' }}
              fontSize="18px"
              lineHeight="32px"
              textAlign="left"
            >
              {copy.description}
            </Text>
            <HStack marginTop={'20px !important'} gap={'6px'}>
              <Link to={copy.documentation.link}>
                <Button bg='apryseIndigo' mt='2px' color="white"  _hover={{ bg: 'lightBlue' }}>
                  {copy.documentation.label}
                </Button>
              </Link>
              <Link to={copy.demo.link}>
                <Button bg='transparent' border='1px' borderColor='apryseIndigo' mt='2px' color="apryseIndigo" _hover={{ borderColor: 'lightBlue' }}>
                  {copy.demo.label}
                </Button>
              </Link>
            </HStack>
          </VStack>
          </Box>

          <Box position="absolute" right="0px" left={{ base: '220px', sm: 'auto' }} top="0px">
            <DocHeroPattern height={{base: '250px', sm: '216px'}}></DocHeroPattern>
          </Box>
        </ContentContainer>
      </Box>
      <ContentContainer noPadding>
        <Flex py={{ base: '16px', sm: '40px', lg: '40px' }} px={{ base: '16px', sm: '0px', md: '60px', lg: '0px' }}>
          <Wrap spacing="80px">
            {copy.sections.map((section: SectionCopy, index: number) => {
              return (
                <Section
                  key={`${index}-${section.title}`}
                  title={section.title}
                  horizontalStack={section.horizontalStack}
                >
                  <SectionContent section={section} />
                </Section>
              );
            })}
          </Wrap>
        </Flex>
      </ContentContainer>
    </>
  );
}
