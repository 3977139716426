import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

export default chakra(function DocHeroPattern({ className }) {
  return (
    <Icon className={className} width="320" viewBox="0 0 320 168" fill="none">
      <rect
        x="137.656"
        y="75.237"
        width="60.198"
        height="60.198"
        rx="2.63485"
        transform="rotate(-15 137.656 75.237)"
        stroke="url(#paint0_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <rect
        x="121.789"
        y="77.328"
        width="79.3595"
        height="79.3595"
        rx="2.91706"
        transform="rotate(-25 121.789 77.328)"
        stroke="url(#paint1_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <rect
        x="106.536"
        y="84.5325"
        width="97.6273"
        height="97.6273"
        rx="2.91706"
        transform="rotate(-35 106.536 84.5325)"
        stroke="url(#paint2_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <rect
        x="91.5855"
        y="96.52"
        width="117.287"
        height="117.287"
        rx="3.58272"
        transform="rotate(-45 91.5855 96.52)"
        stroke="url(#paint3_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <rect
        x="75.971"
        y="114.764"
        width="141.737"
        height="141.737"
        rx="4.64058"
        transform="rotate(-55.4886 75.971 114.764)"
        stroke="url(#paint4_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <rect
        x="63.1758"
        y="134.623"
        width="166.429"
        height="166.429"
        rx="5.51645"
        transform="rotate(-63.8915 63.1758 134.623)"
        stroke="url(#paint5_linear_168_4960)"
        strokeWidth="0.833444"
      />
      <defs>
        <linearGradient
          id="paint0_linear_168_4960"
          x1="167.662"
          y1="74.9424"
          x2="167.662"
          y2="135.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_168_4960"
          x1="161.331"
          y1="77.1265"
          x2="161.331"
          y2="157.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_168_4960"
          x1="155.186"
          y1="84.4302"
          x2="155.186"
          y2="182.891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_168_4960"
          x1="150.056"
          y1="96.52"
          x2="150.056"
          y2="214.641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_168_4960"
          x1="146.677"
          y1="114.872"
          x2="146.677"
          y2="257.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_168_4960"
          x1="146.25"
          y1="134.813"
          x2="146.25"
          y2="302.076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2EA" />
          <stop offset="0.768946" stopColor="#0176CA" stopOpacity="0.09" />
          <stop offset="1" stopColor="#0206A8" stopOpacity="0.31" />
        </linearGradient>
      </defs>
    </Icon>
  );
});
